

































































































































































































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator'
import {Member, VerificationStatus} from '~/components/data-class/data-class'
import {createRequest} from '~/utils/network-request'
import badges from '~/data/badge'
import {checkPermissions} from '~/utils/check-permission'
import {MemberPermission} from '~/utils/permissions'
import {schoolList, schoolLookup} from "@afterschool.dev/as-data-admin";

@Component({
    components: {}
})
export default class AccountInfo extends Vue {
    @Prop({
        default: () => {
            return new Member()
        }
    })
    value: Member

    //add diamond
    visibleD = false
    amountD = 0
    remarkD = ''

    //add coin
    visibleC = false
    amountC = 0
    remarkC = ''

    dialogChangeSchool = false
    selectedSchool: number = 0
    schoolOptions: { label: string, value: number }[] = []

    canEdit = false

    selectedReferrer = ''

    VerificationStatusStr = {
        [VerificationStatus.VERIFIED]: 'Verified',
        [VerificationStatus.NOT_VERIFIED]: 'Not Verified',
        [VerificationStatus.REJECTED]: 'Rejected',
    }
    async created() {
        this.canEdit = await checkPermissions(MemberPermission.SetMemberData)
    }

    get wallet() {
        return this.value.member_wallet
    }
    async addDiamond() {
        if (!this.amountD) {
            this.$message('Please input amount')
            return
        }
        if (Math.floor(this.amountD) !== Math.ceil(this.amountD)) {
            this.$message('Amount should be integer')
            return
        }
        if (this.remarkD === '') {
            this.$message('Remark required')
            return
        }

        const load = this.$loading({fullscreen: true})
        await createRequest('/member/' + this.value._id + '/diamond/update', 'post', {}, {
            amount: this.amountD,
            remark: this.remarkD
        }).send()
        this.update()
        this.visibleD = false
        load.close()
    }
    async addCoin() {
        if (!this.amountC) {
            this.$message('Please input amount')
            return
        }
        if (Math.floor(this.amountC) !== Math.ceil(this.amountC)) {
            this.$message('Amount should be integer')
            return
        }
        if (this.remarkC === '') {
            this.$message('Remark required')
            return
        }

        const load = this.$loading({fullscreen: true})
        await createRequest('/member/' + this.value._id + '/coin/update', 'post', {}, {
            amount: this.amountC,
            remark: this.remarkC
        }).send()
        this.update()
        this.visibleC = false
        load.close()
    }

    school(schoolNo: number): string {
        const obj: object = schoolLookup[schoolNo + '']
        if (obj != undefined) {
            return schoolLookup[schoolNo + ''].zh
        }
        return 'N/A'
    }

    badge(badgeNo: number): string {
        const obj: object = badges[badgeNo + '']
        if (obj != undefined) {
            return badges[badgeNo + ''].zh
        } else {
            return this.school(badgeNo)
        }
    }
    async saveStatus() {
        await createRequest(`/${this.value._id}/user-profile`, 'patch', {}, {status: this.value.status})
            .send()
        this.$message({
            message: 'Success',
            type: 'success'
        })
    }
    async saveDescription() {
        await createRequest(`/${this.value._id}/user-profile`, 'patch', {}, {description: this.value.description})
            .send()
        this.$message({
            message: 'Success',
            type: 'success'
        })
    }
    async setReferrer() {
        this.$confirm('Sure to set referrer?')
            .then(async () => {
                await createRequest(`/members/referral`, 'post', {}, {
                    referrer: this.selectedReferrer,
                    referee: this.value._id
                }).send()
                this.update()
            })
    }

    startSelectSchool() {
        this.selectedSchool = this.value.school
        this.schoolOptions = schoolList.map(s => ({
            value: s.index,
            label: s.zh
        }))
        this.dialogChangeSchool = true
    }

    filterSchool(keyword: string) {
        if (!keyword) {
            this.schoolOptions = schoolList.map(s => ({
                value: s.index,
                label: s.zh
            }))
        } else {
            const k = keyword.toLowerCase()
            this.schoolOptions = schoolList.filter(s => {
                return s.en.toLowerCase().includes(k) || s.zh.includes(k) || s.en_shortForm.toLowerCase().includes(k)
            }).map(s => {
                return {
                    value: s.index,
                    label: s.zh
                }
            })
        }
    }
    async changeSchoolSubmit() {
        this.dialogChangeSchool = false
        await createRequest(`/${this.value._id}/user-profile`, 'patch', {}, {
            school: this.selectedSchool
        }).send()
        this.$message({
            message: 'Success',
            type: 'success'
        })
        this.update()
        this.dialogChangeSchool = false
    }

    @Emit('update')
    update() {
    }
}
