import {Course, DSEResult, Member, Role, Wallet} from '~/components/data-class/data-class'
import {Badge} from 'element-ui'

export class MemberPublicProfile extends Member {
    _id: string = ''
    member_id: string = ''

    display_name: string = ''
    level: number = 0
    lv: number = 0
    form: string = ''
    elective: number[] = []
    status: string = ''
    description: string = ''
    title: number = 0
    role: Role = Role.NONE
    in_rank: boolean = false
    profile_picture_url: string = ''
    post_count: number = 0
    comment_count: number = 0
    best_count: number = 0
    vote_count: number = 0
    highest_streak: number = 0
    dse_result?: DSEResult
    school: number

    badges?: Badge[]
    students?: number
    courses?: Course[]
    // instructor?: InstructorPublicProfile;
}

export class MemberPrivateProfile extends MemberPublicProfile {
    email: string = ''
    phone: string = ''
    dse_result: DSEResult
    hide_school: boolean = false
    next_school_changeable = ''
    member_wallet: Wallet
    name: string = ''

    last_redeemed_lv: number = 1

    member_created_date: string = ''

    google_id: string = ''
    facebook_id: string = ''
    apple_id: string = ''

    // streak: Streak = new Streak()
    stars: number = 0

    referrer: string = ''

    titles: number[]
    // banInfo: BanRecord

    cart?: object
    cart_count?: number
}
