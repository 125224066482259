























import {checkPermissions} from '~/utils/check-permission'
import {createRequest} from '~/utils/network-request'
import {
    AdminPermission,
    BannerPermission,
    BlogPermission,
    CoursePermission,
    LogPermission,
    MemberPermission,
    MessagePermission,
    OrderPermission,
    PromotionPermission,
    ProofFactorPermission,
    ReportPermission,
    ShopPermission,
    ShortenLinkPermission,
    StickyBarPermission
} from '~/utils/permissions'
import {Component, Prop, Vue} from 'vue-property-decorator'

@Component({
    components: {}
})
export default class Permissions extends Vue {
    @Prop({
        default: () => {
            return ''
        }
    })
    value: string

    tree: any

    data: any[] = []
    checked: string[] = []
    org: string
    // changed = false

    editable = false

    enums = [{
        label: 'admin',
        enum: AdminPermission
    }, {
        label: 'member',
        enum: MemberPermission
    }, {
        label: 'course',
        enum: CoursePermission
    }, {
        label: 'report',
        enum: ReportPermission
    }, {
        label: 'blog',
        enum: BlogPermission
    }, {
        label: 'shop',
        enum: ShopPermission
    }, {
        label: 'banner',
        enum: BannerPermission
    }, {
        label: 'shorten link',
        enum: ShortenLinkPermission
    }, {
        label: 'log',
        enum: LogPermission
    }, {
        label: 'proof-factor',
        enum: ProofFactorPermission
    }, {
        label: 'promotion',
        enum: PromotionPermission
    }, {
        label: 'order',
        enum: OrderPermission
    }, {
        label: 'message',
        enum: MessagePermission
    }, {
        label: 'sticky bar',
        enum: StickyBarPermission
    }]

    async created() {
        this.data = this.enums.map((e) => {
            return {
                id: e.label,
                label: e.label,
                children: Object.keys(e.enum).map((k) => {
                    return {
                        id: e.enum[k],
                        label: e.enum[k]
                    }
                })
            }
        })

        const res = await createRequest(`/member/${this.value}/permissions`, 'GET').send()
        const enumLabels = this.enums.map((data) => data.label)
        this.checked = res.data.permissions.filter((p) => !enumLabels.includes(p))
        this.org = JSON.stringify(this.checked)
        this.editable = await checkPermissions(MemberPermission.SetMemberPermissions)
    }

    mounted() {
        this.tree = this.$refs.tree
    }

    getChecked() {
        return this.tree.getCheckedKeys()
    }

    get changed() {
        return this.org !== JSON.stringify(this.getChecked())
    }

    async save() {
        const enumLabels = this.enums.map((data) => data.label)
        this.checked = this.getChecked().filter((p) => !enumLabels.includes(p))


        await createRequest(`/member/${this.value}/permissions`, 'POST', {}, {
            permissions: this.getChecked()
        }).send()

        this.$message({
            message: 'Saved',
            type: 'success'
        })
    }
}
