const badges: { [key: string]: any } = {
  "0": {
    "en": "First blow",
    "zh": "第一擊"
  },
  "1": {
    "en": "Willing to help",
    "zh": "樂於助人"
  },
  "2": {
    "en": "Got some talents",
    "zh": "有啲料到"
  },
  "3": {
    "en": "Studybug",
    "zh": "學霸"
  },
  "4": {
    "en": "Stargazer",
    "zh": "星之子"
  },
  "5": {
    "en": "Appear in newspapers",
    "zh": "放榜等見報"
  },
  "6": {
    "en": "First Question",
    "zh": "第一問"
  },
  "7": {
    "en": "Ask at will",
    "zh": "唔識就問"
  },
  "8": {
    "en": "Problematic kid",
    "zh": "問題少年"
  },
  "9": {
    "en": "Question Frenzy",
    "zh": "瘋狂問問題"
  },
  "10": {
    "en": "Ask more, Learn more",
    "zh": "問得多學得多"
  },
  "11": {
    "en": "Extreme problematic kid",
    "zh": "究極問題少年"
  },
  "12": {
    "en": "Mob",
    "zh": "路人"
  },
  "13": {
    "en": "Warrior",
    "zh": "勇者"
  },
  "14": {
    "en": "Mission Pioneer",
    "zh": "任務先鋒"
  },
  "15": {
    "en": "Mission Slave",
    "zh": "任務奴隸"
  },
  "16": {
    "en": "Mission Pro",
    "zh": "任務達人"
  },
  "17": {
    "en": "Acknowledged",
    "zh": "別人的認同"
  },
  "18": {
    "en": "Stay calm and upvote",
    "zh": "正評太易了~"
  },
  "19": {
    "en": "100th up vote",
    "zh": "第100正評"
  },
  "20": {
    "en": "Embrace my answers",
    "zh": "正評請分享"
  },
  "21": {
    "en": "Upvote madness",
    "zh": "被瘋狂正評"
  },
  "22": {
    "en": "Global Acknowledgement",
    "zh": "全世界的認同"
  },
  "23": {
    "en": "On the way",
    "zh": "終於上榜了"
  },
  "24": {
    "en": "Top 10",
    "zh": "擠身頭十"
  },
  "25": {
    "en": "Top of the list",
    "zh": "名列前茅"
  },
  "26": {
    "en": "Number One",
    "zh": "第一名"
  },
  "27": {
    "en": "Newbie",
    "zh": "新手上路"
  },
  "28": {
    "en": "Novice",
    "zh": "無名小卒"
  },
  "29": {
    "en": "Apprentice",
    "zh": "離開新手村"
  },
  "30": {
    "en": "Activist",
    "zh": "活躍分子"
  },
  "31": {
    "en": "Loyal fan",
    "zh": "忠實粉絲"
  },
  "32": {
    "en": "Veteran",
    "zh": "老手"
  },
  "33": {
    "en": "Professional",
    "zh": "有咩風浪我未見過"
  },
  "34": {
    "en": "Getting started",
    "zh": "初出茅廬"
  },
  "35": {
    "en": "Born",
    "zh": "初哥"
  },
  "36": {
    "en": "A Month",
    "zh": "一個月"
  },
  "37": {
    "en": "Hundredth",
    "zh": "百日宴"
  },
  "38": {
    "en": "Senpai",
    "zh": "前輩"
  },
  "39": {
    "en": "Teacher",
    "zh": "老是常出現"
  },
  "40": {
    "en": "Guru",
    "zh": "我愛AfterSchool"
  }
};

export default badges;
