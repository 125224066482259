






























































import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator'
import {Member, Role, Instructor} from '~/components/data-class/data-class'
import {createRequest} from '~/utils/network-request'
import {toInstanceForce} from '~/utils/Serializer'
import {courseSubjects} from '@afterschool.dev/as-data-admin'

@Component({
    components: {}
})
export default class TutorInfo extends Vue {
    @Prop({
        default: () => {
            return new Member()
        }
    })
    value: string
    profile: Instructor = new Instructor()
    baseSubjects: Array<{ value: string, label: string }> = courseSubjects.map((subject) => {
        return {
            value: subject,
            label: subject
        }
    })
    async created() {
        const res = await createRequest('/instructor/' + this.value + '/profile', 'get').send()
        toInstanceForce(this.profile, res.data.instructor)
    }

    @Emit('update')
    update() {
    }
}
