















































































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator'
import {checkPermissions} from '~/utils/check-permission'
import {MemberPermission} from '~/utils/permissions'
import {MemberPrivateProfile} from '~/components/member/member-models'
import {createRequest} from '~/utils/network-request'
import {VBtn, VCard, VCardActions, VCardText, VCardTitle, VCheckbox, VDialog, VSpacer, VTextField} from 'vuetify/lib'


@Component({
    components: {
        VBtn,
        VDialog,
        VCard,
        VCardTitle,
        VCardText,
        VCardActions,
        VSpacer,
        VCheckbox,
        VTextField
    }
})
export default class MemberCommunity extends Vue {
    @Prop({
        default: () => {
            return new MemberPrivateProfile()
        }
    })
    value: MemberPrivateProfile

    //add diamond
    visibleD = false
    amountD = 0
    remarkD = ''

    //add coin
    visibleC = false
    amountC = 0
    remarkC = ''

    canEdit = false

    // ban
    reasons: string[] = []
    banDays = 7
    banModal: boolean = false

    selectedReferrer = ''

    async created() {
        this.canEdit = await checkPermissions(MemberPermission.SetMemberData)
    }

    get memberId() {
        return this.value._id
    }

    get banInfo() {
        return this.value.banInfo!
    }

    get banned() {
        return !!this.banInfo
    }

    ban() {
        const reasons: number[] = []
        this.reasons.forEach((reasonStr) => {
            reasons.push(Number(reasonStr))
        })
        const body = {
            target_user: this.memberId,
            reason: reasons,
            days: this.banDays
        }
        createRequest('/member/ban', 'post', {}, body).send()
            .then((res) => {
                this.value.banInfo = {
                    executor: '',
                    reason: body.reason.toString(),
                    days: body.days,
                    end_time: new Date().setDate(new Date().getDate() + body.days)
                }
                this.banModal = false
                this.update()
            })
    }

    unban() {
        createRequest('/member/' + this.memberId + '/ban', 'delete').send()
            .then((res) => {
                this.value.banInfo = null
                this.banModal = false
                this.update()
            })
    }

    banReasonStr(reasonStr: string) {
        let outputStr = ''
        for (let i = 0; i < reasonStr.length; i++) {
            const reason = Number(reasonStr[i])
            if (reason == 0) outputStr += 'Spam'
            else if (reason == 1) outputStr += 'Improper content'
            else if (reason == 2) outputStr += 'Multi accounts'
            else outputStr += reason
            if (reasonStr.length - 1 > i) outputStr += ', '
        }
        return outputStr
    }

    @Emit('update')
    update() {
    }
}
