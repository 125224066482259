














































































































































































































































































































import copy from 'copy-to-clipboard'
import moment from 'moment'
import {Component, Vue} from 'vue-property-decorator'
import {VBtn, VCard, VCardActions, VCardText, VCardTitle, VCheckbox, VDialog, VSelect, VSpacer, VTextField} from 'vuetify/lib'
import {Role} from '~/components/data-class/data-class'
import MemberData from '~/components/member/member-data'
import {MemberPrivateProfile} from '~/components/member/member-models'
import PhoneWts from '~/components/phone-wts.vue'
import dseMarks from '~/data/dse-marks'
import subjectsRaw from '~/data/subjects'
import {checkPermissions} from '~/utils/check-permission'
import {userPropicURL} from '~/utils/misc'
import {createRequest} from '~/utils/network-request'
import {MemberPermission} from '~/utils/permissions'
import MemberCommunity from '~/views/member/member-community.vue'
import Permissions from '~/views/member/permissions.vue'
import TutorInfo from '~/views/member/tutor-info.vue'

import AccountInfo from './account-info.vue'


@Component({
    components: {
        TutorInfo,
        PhoneWts,
        AccountInfo,
        Permissions,
        MemberCommunity,
        VBtn,
        VDialog,
        VCard,
        VCardTitle,
        VCardText,
        VCardActions,
        VSpacer,
        VCheckbox,
        VTextField,
        VSelect
    }
})
export default class MemberView extends Vue {
    static permission = MemberPermission.ViewMemberData

    member: MemberPrivateProfile = new MemberPrivateProfile()
    update: { [key: string]: any } = {}
    password = ''
    subjects: Array<{ id: string, name: string }> = []
    electiveIndex: number[] = []
    elective: string[] = []

    subjectsRaw = subjectsRaw
    dseMarks = dseMarks

    forceDiamond: number = 0
    forceRemark: string = ''

    Role = Role

    tabs = ['account']
    activeTab = this.tabs[0]

    viewPermissions = false
    setRole = false
    setPassword = false

    //change email
    newEmail: string = ''
    emailPopover: boolean = false

    //change name
    newName: string = ''
    namePopover: boolean = false

    //change phone
    newPhone: string = ''
    phonePopover: boolean = false

    // xban
    banDaysOptions = [1, 2, 3, 4, 5, 6, 7, 28, 9999]
    banModal: boolean = false
    banDays: number | '' = ''

    reason = ''


    roles = [{label: '---', value: ''},
        {label: 'Graduate', value: 'GRADUATE'},
        {label: 'G. Unverified', value: 'GRADUATE_UNVERIFIED'},
        {label: 'G. Rejected', value: 'GRADUATE_REJECTED'},
        {label: 'Tutor', value: 'TUTOR'},
        {label: 'Admin', value: 'ADMIN'},
        {label: 'SAdmin', value: 'SADMIN'}]

    get memberId() {
        return this.$route.params['id']
    }

    get xban() {
        return this.member.xban
    }

    set xban(val) {
        this.$set(this.member, 'xban', val)
    }

    get xbanned() {
        return !!this.xban && this.xban.until > Date.now()
    }

    get xbanEnd() {
        return moment(this.xban!.until).format('D/MM/YYYY')
    }

    get propicURL() {
        return userPropicURL(this.memberId, 100)
    }

    async created() {
        const t = this.$route.query['tab'] as string
        if (t && this.tabs.includes(t)) {
            this.activeTab = t
        }
        await this.getMember()
        this.viewPermissions = await checkPermissions(MemberPermission.ViewMemberPermissions)
        this.setRole = await checkPermissions(MemberPermission.SetRole)
        this.setPassword = await checkPermissions(MemberPermission.SetPassword)
        this.newEmail = this.member.email
    }

    async mounted() {
        window['loginToken'] = async () => {
            const res = await createRequest(`/${this.memberId}/custom-token`, 'get').send()
            copy(res.data.token, {
                debug: true,
                message: 'Press #{key} to copy',
            })
            this.$message.success('Copied')
        }
        window['loginTokenS'] = async () => {
            const res = await createRequest(`/auth/${this.$auth.memberId}/login-token`, 'get').send()
            copy(res.data.login_token, {
                debug: true,
                message: 'Press #{key} to copy',
            })
            this.$message.success('Copied')
        }
        window['accessToken'] = async () => {
            const res = await createRequest(`/${this.memberId}/auth-tokens`, 'get').send()
            copy(res.data.access_token, {
                debug: true,
                message: 'Press #{key} to copy',
            })
            this.$message.success('Copied')
        }
    }

    async getMember() {
        this.member = await MemberData.updateMember(this.memberId) as MemberPrivateProfile

        this.electiveIndex = []
        this.elective = []
        for (let i = 0; i < this.member.elective.length; i++) {
            this.electiveIndex.push(i)
            this.elective.push(this.member.elective[i] + '')
        }
        document.title = this.member.display_name

        this.subjects = []
        const keys = Object.keys(subjectsRaw)
        for (const key in keys) {
            if (subjectsRaw[key]) {
                this.subjects.push({id: key, name: subjectsRaw[key]})
            }
        }
    }

    ban() {
        const body = {
            member_id: this.memberId,
            reason: this.reason,
            days: this.banDays
        }
        createRequest('/x-ban', 'post', {}, body).send()
            .then((res) => {
                this.xban = res.data.xban
                this.banModal = false
            })
    }

    unban() {
        createRequest('/x-ban/' + this.memberId, 'delete').send()
            .then((res) => {
                this.xban = null
                this.banModal = false
            })
    }

    tabChanged() {
        this.$router.replace({query: {tab: this.activeTab}})
    }

    viewSystemLog() {
        this.$router.push({path: '/system-log', query: {mid: this.memberId}})
    }

    viewDiamondLog() {
        this.$router.push({path: '/diamond-logs', query: {mid: this.memberId}})
    }

    sendChangePassword() {
        if (this.password.length < 6) {
            alert('password should be at least 5 characters')
        }
        createRequest(this.memberId + '/password', 'patch', {}, {
            old_password: '',
            new_password: this.password
        }).send()
            .then((res) => {
                this.$message({
                    message: 'Password updated!',
                    type: 'success'
                })
            }).catch((e) => {
            this.$message({
                message: e,
                type: 'error'
            })
        })
    }

    dateTimeStr(rawStr: string) {
        let str = rawStr

        function strIns(idx: number, val: string) {
            str = str.slice(0, idx) + val + str.slice(idx)
        }

        strIns(12, ':')
        strIns(10, ':')
        strIns(8, ' ')
        strIns(6, '/')
        strIns(4, '/')
        return str
    }

    isGraduate(role: string): boolean {
        if (role == 'GRADUATE') {
            return true
        } else if (role == 'GRADUATE_UNVERIFIED') {
            return true
        } else return role == 'GRADUATE_REJECTED'
    }

    saveRole() {
        createRequest('/' + this.memberId + '/user-profile', 'patch', {}, {role: this.member.role}).send()
            .then((res) => {
                this.$message({
                    message: 'Role updated!',
                    type: 'success'
                })
            }).catch((e) => {
            this.$message({
                message: e,
                type: 'error'
            })
        })
    }

    dateStr(rawStr: string) {
        let str = rawStr || ''

        function strIns(idx: number, val: string) {
            str = str.slice(0, idx) + val + str.slice(idx)
        }

        strIns(6, '/')
        strIns(4, '/')
        return str
    }

    async changeEmail() {
        this.emailPopover = false
        await createRequest(`/${this.member._id}/user-profile`, 'patch', {}, {email: this.newEmail})
            .send()
        this.$message({
            message: 'Success',
            type: 'success'
        })
        await this.getMember()
    }

    async changeName() {
        this.namePopover = false
        await createRequest(`/${this.member._id}/user-profile`, 'patch', {}, {name: this.newName})
            .send()
        this.$message({
            message: 'Success',
            type: 'success'
        })
        await this.getMember()
    }

    async changePhone() {
        if (!this.newPhone.match(/^[\d+]{8}$/)) {
            this.$alert('Invalid phone')
            return
        }

        const pRes = await createRequest('/exists/phone', 'get', {
            phone: this.newPhone
        }).send()

        if (pRes.data.exists) {
            this.$alert('Phone used by other user')
            return
        }

        this.phonePopover = false
        await createRequest(`/${this.member._id}/user-profile`, 'patch', {}, {
            phone: '+852' + this.newPhone
        }).send()
        this.$message({
            message: 'Success',
            type: 'success'
        })
        await this.getMember()
    }
}
